import { kumbh2025Page, kumbh2025PageChildren } from './page-kumbh-2025';
import { packagesPage, packagesPageChildren } from './page-packages';
import { PageRoutes } from './page-routes';
import { theCampPage, theCampPageChildren } from './page-the-camp';
import { PageHierarchyItem } from './pages-types';

export const pages: PageHierarchyItem[] = [
  {
    title: theCampPage.title,
    path: theCampPage.path,
    children: theCampPageChildren.map((child) => ({
      title: child.title,
      path: child.path,
    })),
  },
  {
    title: kumbh2025Page.title,
    path: kumbh2025Page.path,
    children: kumbh2025PageChildren.map((child) => ({
      title: child.title,
      path: child.path,
    })),
  },
  {
    title: packagesPage.title,
    path: packagesPage.path,
    children: packagesPageChildren.map((child) => ({
      title: child.title,
      path: child.path,
    })),
  },
  {
    title: 'camp67-navigation.contact-us',
    path: PageRoutes.contactUs,
    children: [],
  },
];
